import { React, useEffect, useState } from "react";
import axios from "axios";
import webContent from "./Config";
import { MenuItems } from "./MenuItems";
import ClipLoader from "react-spinners/ClipLoader";
export const MenuList = () => {
  const [type, setType] =  useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchItems = async () => {
    setLoading(true);
    axios
      .post(
        webContent.webLink + "/api/menuItems.php",
        { SUBMIT: "CATEGORY", TYPE: "" },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //alert(res.data)
        console.log(res.data);
        setLoading(false);
        setItems(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  const typeHandler = (cid) => {
    //alert(cid)
    //setLoading(true);
    setType(cid);
    //navigate("/menu/" + cid);
    fetchItems();
  };
  useEffect(() => {
    setType("");
    fetchItems();
  }, []);
  return (
    <>
      <section className="recipe_menu_section sec_ptb_120 bg_gray deco_wrap">
        <div className="container">
          <div className="section_title text-uppercase text-center">
            <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
              <i className="fas fa-coffee"></i> Our special Menu
            </h2>
            <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
              CAFE HOUSE MENU
            </h3>
          </div>

          <ul
            className="filters-button-group ul_li_center wow fadeInUp"
            data-wow-delay=".3s"
          >
            <li>
              <button onClick={() => typeHandler('')} className={type==='' ? "button text-uppercase active" : "button text-uppercase"} data-filter="*">
                all
              </button>
            </li>

            {items.length > 0 &&
              items.map((categoryObj, index) => (
                <button
                  onClick={() => typeHandler(categoryObj.categoryDetails.id)}
                  className={type===categoryObj.categoryDetails.id ? "button text-uppercase active" : "button text-uppercase"}
                  style={categoryObj.categoryDetails.id==13 ? {width:"200px"} : {width:"auto"}}
                >
                  {categoryObj.categoryDetails.name}
                </button>
              ))}
          </ul>

          <div
            className="recipe_item_grid grid wow fadeInUp menu_details"
            data-wow-delay=".4s"
          >
            {loading ? (
              <div style={{ textAlign: "center", margin: "20px" }}>
                <ClipLoader
                  color="#ee0979"
                  // loading={loading}
                  // cssOverride={override}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <MenuItems state={{ type: type }} />
            )}
          </div>
        </div>

        <div className="deco_item shape_1">
          <img src="/assets/images/menu/shape_01.png" alt="image_not_found" />
        </div>
        <div className="deco_item shape_2">
          <img src="/assets/images/menu/shape_02.png" alt="image_not_found" />
        </div>
      </section>
    </>
  );
};
