import React from "react";
import { SmallAbout } from "../include/SmallAbout";

export const Aboutus = () => {
  return (
    <>
      <div class="body_wrap">
        <main>
          <section
            class="breadcrumb_section text-uppercase"
            style={{
              backgroundImage:
                "url(assets/images/breadcrumb/breadcrumb_bg_01.jpg)",
            }}
          >
            <div class="container">
              <h1
                class="page_title text-white wow fadeInUp"
                data-wow-delay=".1s"
              >
                About us
              </h1>
              <ul
                class="breadcrumb_nav ul_li wow fadeInUp"
                data-wow-delay=".2s"
              >
                <li>
                  <a href="<?php echo $link;?>">
                    <i class="fas fa-home"></i> Home
                  </a>
                </li>
                <li>About Us</li>
              </ul>
            </div>
            <div class="breadcrumb_icon_wrap">
              <div class="container">
                <div class="breadcrumb_icon wow fadeInUp" data-wow-delay=".3s">
                  <img
                    src="assets/images/feature/icon_01.png"
                    alt="icon_not_found"
                  />
                  <span class="bg_shape"></span>
                </div>
              </div>
            </div>
          </section>

          <section class="about_section sec_ptb_20">
            <div class="container">
              <div class="our_history_area">
                <div class="row align-items-center">
                  <div class="col-lg-2 col-md-2">
                    <div class="item_icon wow fadeInUp" data-wow-delay=".1s">
                      <img
                        src="assets/images/feature/icon_01.png"
                        alt="icon_not_found"
                      />
                      <span class="bg_shape"></span>
                    </div>
                  </div>
                  <div
                    class="col-lg-5 col-md-5 order-first wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    <h3 class="item_title text-uppercase">
                      About Cafe House Sukna
                    </h3>
                  </div>
                  <div class="col-lg-5 col-md-5">
                    <p class="wow fadeInUp" data-wow-delay=".3s">
                      Cafe House Sukna is the latest wing added to Techno
                      India Group's crown, targeting the Youths & Foreigners, is
                      nestled amongst the misty greens of the scenic beauty &
                      view point in Siliguri. The coffee lovers can experience
                      the picturesque Himalayan peaks, gushing streams and
                      swaying orchids whilst they sip on the choicest selection
                      of coffee beads along with variety of teas. It will offer
                      you an experience of a lifetime with home brewed coffee,
                      aromatic tea and mouth-watering snacks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SmallAbout />
        </main>
      </div>
    </>
  );
};
