import { React, useEffect } from "react";
import { MenuList } from "../include/MenuList";

export const Menu = () => {
  useEffect(() => {
    localStorage.removeItem("tableNo");
  });
  return (
    <>
      <MenuList />
    </>
  );
};
