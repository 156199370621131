import { React, useContext, useEffect, useState } from "react";
import { CartContext } from "../context/CartContext";
import webContent from "./Config";
import { SiteContext } from "../context/SiteContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
export const CartItems = () => {
  const {
    cartitems,
    withoutBevAmount,
    beverageAmount,
    addtoCart,
    removeItem,
    gst,
    emptyCart,
    cartPanelClose,
    
  } = useContext(CartContext);
  const [loader, setLoader] = useState(false);
  const { siteData } = useContext(SiteContext);
  const navigate = useNavigate();
  const cartUpdate = (product, qty) => {
    addtoCart({ product, qty });
  };

  const handleRemove = (item) => {
    removeItem({ item });
  };
  useEffect(() => {
    setLoader(false);
  }, []);

  const handleOrder = () => {
    setLoader(true);
    //cartitems.forEach((element) => {
    //alert(gst+"#####"+localStorage.getItem('tableNo'),)
    axios
      .post(
        webContent.webLink + "/api/takeOrder.php",
        {
          SUBMIT: "TAKEORDER",
          ORDER_ID: localStorage.getItem("orderId"),
          GST: gst,
          TABLE_NO: localStorage.getItem("tableNo"),
          GROSS_AMOUNT: withoutBevAmount,
          BEV_AMOUNT: beverageAmount,
          NET_AMOUNT: Math.round(withoutBevAmount + gst + beverageAmount),
          DATA_ITEMS: JSON.stringify(cartitems),
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //alert(res.data.orderid)
        //cartitems.clear();
        //cartitems([]);
        cartPanelClose();
        setLoader(false);
        emptyCart();
        navigate("/success/" + res.data.orderid);
        //setItems(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
    //});
  };

  return (
    <>
      <div class="sidebar-menu-wrapper">
        <div class="cart_sidebar">
          <h2 class="heading_title text-uppercase">
            <button
              type="button"
              class="close_btn"
              onClick={() => {
                cartPanelClose();
              }}
            >
              <i class="fal fa-times"></i>
            </button>
            Cart Items -{" "}
            <span>
              {cartitems.length} {siteData.localip}
            </span>
          </h2>

          <div class="cart_items_list">
            {cartitems.length > 0 &&
              cartitems.map((item, index) => (
                <div
                  class="cart_item"
                  style={{ display: "table", width: "100%", clear: "both" }}
                >
                  <div style={{ float: "left" }}>
                    <img
                      style={{
                        width: "100px",
                        maxWidth: "none",
                        minWidth: "auto",
                        marginRight: "5px",
                      }}
                      src={
                        webContent.webLink + "/images/product/" + item.images
                      }
                      alt="image_not_found"
                    />
                  </div>
                  <div
                    class="item_content"
                    style={{ float: "left", width: "200px" }}
                  >
                    <h4 class="item_title">{item.name}</h4>
                    <span class="item_price">
                      {item.quantity}x{item.price} ={" "}
                      {item.quantity * item.price}
                      <select
                        class="btn btn-primary"
                        style={{
                          background: "#f0ddc3",
                          borderRadius: "5px",
                          color: "black",
                          padding: "5px",
                        }}
                        id={"qty" + parseInt(index + 1)}
                        onChange={() =>
                          cartUpdate(
                            item,
                            document.getElementById("qty" + parseInt(index + 1))
                              .value
                          )
                        }
                      >
                        {[...Array(20)]
                          .map((_, i) => i + 1)
                          .map((i) => (
                            <option
                              key={i}
                              value={i}
                              selected={item.quantity == i}
                            >
                              {i}
                            </option>
                          ))}
                      </select>
                    </span>
                    <span></span>
                    <button
                      type="button"
                      class="remove_btn"
                      onClick={() => handleRemove(item)}
                    >
                      <i class="fal fa-times"></i>
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div class="total_price text-uppercase">
            <span>Sub Total:</span>
            <span>
              <i class="fa fa-rupee"></i>
              {withoutBevAmount}
            </span>
          </div>
          <div class="total_price text-uppercase">
            <span>GST:</span>
            <span>
              <i class="fa fa-rupee"></i>
              {gst}
            </span>
          </div>
          {beverageAmount > 0 ? (
            <div class="total_price text-uppercase">
              <span>Beverage Amount:</span>
              <span>
                <i class="fa fa-rupee"></i>
                {beverageAmount}
              </span>
            </div>
          ) : (
            <div></div>
          )}
          <div class="total_price text-uppercase">
            <span>Net Amount:</span>
            <span>
              <i class="fa fa-rupee"></i>
              {Math.round(withoutBevAmount + gst + beverageAmount)}
            </span>
          </div>

          {loader ? (
            <div style={{ textAlign: "center" }}>
              {" "}
              <ClipLoader
                color="#ee0979"
                // loading={loading}
                // cssOverride={override}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <ul class="btns_group ul_li">
              {cartitems.length > 0 ? (
                <li>
                  <button
                    onClick={handleOrder}
                    class="btn btn_primary text-uppercase"
                  >
                    Checkout
                  </button>
                </li>
              ) : (
                <div></div>
              )}
            </ul>
          )}
        </div>
        <div class="cart_sidebar_overlay"></div>
      </div>
    </>
  );
};
