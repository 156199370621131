import { createContext, useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SiteContext } from "../context/SiteContext";
export const CartContext = createContext();

export const CartContextProvider = ({ children }) => {
  const { siteData } = useContext(SiteContext);
  const [cartPanel, setCartPanel] = useState(false);
  const [totalitem, setTotalItem] = useState(0);
  const [cartitems, setCartItems] = useState([]);
  const [withoutBevAmount, setwithoutBevAmount] = useState(0);
  const [beverageAmount, setBeverageAmount] = useState(0);
  const [gst, setGst] = useState(0);

  const addtoCart = ({ product, qty }) => {
    //alert(qty);
    const productExists = cartitems.find((item) => item.id === product.id);
    if (productExists) {
      console.log("update");
      setCartItems(
        cartitems.map((item) =>
          item.id === product.id ? { ...productExists, quantity: qty } : item
        )
      );
      toast.warning("Item Updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    } else {
      setCartItems([...cartitems, { ...product, quantity: qty }]);
      toast.success("Item Added successfully", {
        // Set to 15sec
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const removeItem = ({ item }) => {
    const newPeople = cartitems.filter((items) => items.id !== item.id);
    setCartItems(newPeople);
  };

  const emptyCart = () => {
    setCartItems([]);
  };

  const cartPanelOpen = () => {
    setCartPanel(true);
  };

  const cartPanelClose = () => {
    setCartPanel(false);
  };
  useEffect(() => {
    ////////////////////////TOTAL ITEMS///////////////////
    console.log(cartitems);
    setTotalItem(cartitems.length);

    ////////////////////////BEVERAGE AMOUNT///////////////////
    var result = cartitems.filter((x) => {
      return x.type == 9;
    });
    const totalBevAmount = result.reduce(
      (total, result) => total + result.quantity * result.price,
      0
    );
    setBeverageAmount(totalBevAmount);

    ////////////////////////WITHOUT BEVERAGE AMOUNT///////////////////
    var bevWithouAmount = cartitems.filter((x) => {
      return x.type != 9;
    });
    const withouBevtotalPrice = bevWithouAmount.reduce(
      (total, bevWithouAmount) =>
        total + bevWithouAmount.quantity * bevWithouAmount.price,
      0
    );
    setwithoutBevAmount(withouBevtotalPrice);
    setGst((withouBevtotalPrice * siteData.gst) / 100);
  }, [cartitems]);

  return (
    <CartContext.Provider
      value={{
        addtoCart,
        removeItem,
        emptyCart,
        cartPanelOpen,
        cartPanelClose,
        cartPanel,
        cartitems,
        withoutBevAmount: withoutBevAmount,
        beverageAmount: beverageAmount,
        totalitem: totalitem,
        gst: gst,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
