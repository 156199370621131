import React from "react";
import { useNavigate, useParams } from "react-router-dom";
export default function OrberSuccess() {
  const { id } = useParams();
  localStorage.removeItem("tableNo");
  localStorage.removeItem("orderId");
  return (
    <>
      <div
        class="element-item chocolate greantea "
        data-category="chocolate"
        style={{ marginTop: "130px" }}
      >
        <div className="section_title text-uppercase text-center">
          <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
            <i className="fa fa-warning"></i> ORDER PLACED
          </h2>
          <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
            YOUR ORDER HAS BEEN SUCCESSFULLY PLACED
          </h3>
        </div>
      </div>
    </>
  );
}
