import React, { useEffect } from "react";
import { MenuList } from "../include/MenuList";
import { SmallAbout } from "../include/SmallAbout";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, EffectCube, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import { Link } from "react-router-dom";

export const Home = () => {
  useEffect(() => {
    localStorage.removeItem("tableNo");
  });

  return (
    <>
      <div className="body_wrap">
        <main>
          <section
            className="slider_section slider_dark"
            style={{
              backgroundImage: "url(../assets/images/backgrounds/bg_10.jpg)",
            }}
          >
            <Swiper
              breakpoints={{
                576: {
                  width: 576,
                  slidesPerView: 1,
                },
                768: {
                  width: 768,
                  slidesPerView: 1,
                },
              }}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 2,
                slideShadows: false,
              }}
              loop={false}
              showsPagination={false}
              modules={[Navigation, A11y, EffectCube, Autoplay]}
              spaceBetween={20}
              loopedSlides={false}
              autoplay={true}
              //slidesPerView={2}
              navigation={{
                nextEl: ".main_left_arrow",
                prevEl: ".main_right_arrow",
              }}
              pagination={{ clickable: false }}
              //scrollbar={{ draggable: false }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              //effect={"slide"}
              cubeEffect={{
                shadow: false,
                slideShadows: false,
                shadowOffset: 20,
                shadowScale: 0.94,
              }}
              className="main_slider pb-0 wow fadeInUp"
              data-wow-delay=".1s"
            >
              <SwiperSlide
                className="slider_item text-white"
                style={{
                  backgroundImage: "url(assets/images/slider/img_01.jpg)",
                }}
              >
                <div className="container">
                  <div className="row justify-content-lg-start justify-content-md-center">
                    <div className="col-lg-8 col-md-8">
                      <h3
                        className="title_text text-white text-uppercase"
                        data-animation="fadeInUp"
                        data-delay=".2s"
                      >
                        Time to discover Cafe House Sukna
                      </h3>
                      <p data-animation="fadeInUp" data-delay=".4s">
                        The coffee is brewed by first roasting the green coffee
                        beans over hot coals in a brazier. given an opportunity
                        to sample.
                      </p>
                      <ul
                        className="btns_group ul_li"
                        data-animation="fadeInUp"
                        data-delay=".6s"
                      >
                        <li>
                          <Link
                            className="btn btn_primary text-uppercase"
                            href="menu.html"
                          >
                            testy Coffee
                          </Link>
                        </li>
                        <li>
                          <a
                            className="btn btn_border border_white text-uppercase"
                            href="shop_details.html"
                          >
                            Learn more
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="shape_image">
                  <img
                    src="assets/images/slider/shape_01.png"
                    alt="image_not_found"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="slider_item text-white"
                style={{
                  backgroundImage: "url(../assets/images/slider/img_02.jpg)",
                  backgroundPositionX: "-500px",
                }}
              >
                <div className="container">
                  <div className="row justify-content-lg-start justify-content-md-center">
                    <div className="col-lg-8 col-md-8">
                      <h3
                        className="title_text text-white text-uppercase"
                        data-animation="fadeInUp"
                        data-delay=".2s"
                      >
                        Time to discover cafe house Sukna
                      </h3>
                      <p data-animation="fadeInUp" data-delay=".4s">
                        The coffee is brewed by first roasting the green coffee
                        beans over hot coals in a brazier. given an opportunity
                        to sample.
                      </p>
                      <ul
                        className="btns_group ul_li"
                        data-animation="fadeInUp"
                        data-delay=".6s"
                      >
                        <li>
                          <a
                            className="btn btn_primary text-uppercase"
                            href="menu.html"
                          >
                            testy Coffee
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn btn_border border_white text-uppercase"
                            href="shop_details.html"
                          >
                            Learn more
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="shape_image">
                  <img
                    src="../assets/images/slider/shape_01.png"
                    alt="image_not_found"
                  />
                </div>
              </SwiperSlide>

              <SwiperSlide
                className="slider_item text-white"
                style={{
                  backgroundImage: "url(../assets/images/slider/img_03.jpg)",
                  backgroundPositionX: "-600px",
                }}
              >
                <div className="container">
                  <div className="row justify-content-lg-start justify-content-md-center">
                    <div className="col-lg-8 col-md-8">
                      <h3
                        className="title_text text-white text-uppercase"
                        data-animation="fadeInUp"
                        data-delay=".2s"
                      >
                        Time to discover coffee Sukna
                      </h3>
                      <p data-animation="fadeInUp" data-delay=".4s">
                        The coffee is brewed by first roasting the green coffee
                        beans over hot coals in a brazier. given an opportunity
                        to sample.
                      </p>
                      <ul
                        className="btns_group ul_li"
                        data-animation="fadeInUp"
                        data-delay=".6s"
                      >
                        <li>
                          <a
                            className="btn btn_primary text-uppercase"
                            href="menu.html"
                          >
                            testy Coffee
                          </a>
                        </li>
                        <li>
                          <a
                            className="btn btn_border border_white text-uppercase"
                            href="shop_details.html"
                          >
                            Learn more
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="shape_image">
                  <img
                    src="assets/images/slider/shape_01.png"
                    alt="image_not_found"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="carousel_nav">
              <button className="main_left_arrow" type="button">
                <i className="fal fa-arrow-up"></i>
              </button>
              <button className="main_right_arrow" type="button">
                <i className="fal fa-arrow-down"></i>
              </button>
            </div>
            <div className="slider_social_wrap">
              <div className="container maxw_1560">
                <div className="row align-items-center justify-content-lg-between">
                  <div className="col-lg-6 col-md-4 order-last">
                    <a
                      className="popup_video video_btn1 text-uppercase wow fadeInRight"
                      href="#"
                    >
                      <span className="pulse">
                        <i className="fas fa-play"></i>
                      </span>
                      <small>Play video</small>
                    </a>
                  </div>
                  <div className="col-lg-6 col-md-8">
                    <ul className="social_links social_text ul_li text-uppercase wow fadeInLeft">
                      <li>
                        <a href="https://www.facebook.com/">
                          <i className="fab fa-facebook-f"></i> facebook
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/">
                          <i className="fab fa-twitter"></i> twitter
                        </a>
                      </li>
                      <li>
                        <a href="https://www.youtube.com/">
                          <i className="fab fa-youtube"></i> youtube
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <SmallAbout />

          <MenuList />

          {/* <section className="offer_section">
            <div className="container-fluid p-0">
              <div className="row g-0">
                <div className="col-lg-4">
                  <div className="offer_video wow fadeIn" data-wow-delay=".1s">
                    <div className="overlay"></div>
                    <img
                      src="assets/images/offer/img_01.jpg"
                      alt="image_not_found"
                    />
                    <a
                      className="popup_video video_btn2"
                      href="#"
                    >
                      <span className="pulse">
                        <i className="fas fa-play"></i>
                      </span>
                      <small className="text-uppercase">Play Video</small>
                    </a>
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="offer_area">
                    <div className="offer_image">
                      <img
                        src="assets/images/offer/img_02.png"
                        alt="image_not_found"
                      />
                    </div>
                    <div className="offer_content">
                      <div className="section_title text-uppercase">
                        <h2
                          className="small_title wow fadeInUp"
                          data-wow-delay=".1s"
                        >
                          <i className="fas fa-coffee"></i> what we offer
                        </h2>
                        <h3
                          className="big_title wow fadeInUp"
                          data-wow-delay=".2s"
                        >
                          divine aroma in every single cup
                        </h3>
                      </div>

                      <p className="wow fadeInUp" data-wow-delay=".3s">
                        From a cultural standpoint, coffeehouses largely serve
                        as centers of social interaction: the coffeehouse
                        provides patrons with a place to congregate, talk, read,
                        write, entertain one another, or pass the time, whether
                        individually or in small groups.
                      </p>
                      <p className="mb-0 wow fadeInUp" data-wow-delay=".4s">
                        From a cultural standpoint, coffeehouses largely serve
                        as centers of social interaction: the coffeehouse
                        provides patrons with a place to congregate,
                      </p>
                    </div>
                  </div>

                  <div className="row g-0">
                    <div className="offerinfo_col col-lg-4">
                      <div
                        className="offer_info_item table_reservation_contact wow fadeInUp"
                        data-wow-delay=".1s"
                      >
                        <h3 className="offer_info_title text-uppercase">
                          table Reservation. . . .
                          <i className="fal fa-heart"></i>
                        </h3>
                        <ul className="ul_li_block">
                          <li>
                            <div className="item_icon">
                              <i className="fas fa-phone"></i>
                            </div>
                            <div className="item_content">
                              <span>(008) 01869018907</span>
                              <span>+897 989 543 2344</span>
                            </div>
                          </li>
                          <li>
                            <div className="item_icon">
                              <i className="fas fa-envelope"></i>
                            </div>
                            <div className="item_content">
                              <span>info.@webmail.com</span>
                              <span>israfilsupol@mail.com</span>
                            </div>
                          </li>
                          <li>
                            <div className="item_icon">
                              <i className="fas fa-fax"></i>
                            </div>
                            <div className="item_content">
                              <span>08744,Sadar pur 8973</span>
                              <span>08744,Sadar pur 8973</span>
                            </div>
                          </li>
                        </ul>
                        <a
                          className="btn btn_secondary text-uppercase"
                          href="contact.html"
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>

                    <div className="offerinfo_col col-lg-4">
                      <div
                        className="offer_info_item opening_time text-uppercase text-white wow fadeInUp"
                        data-wow-delay=".2s"
                        style={{
                          backgroundImage: "url(assets/images/offer/bg_01.png)",
                        }}
                      >
                        <h3 className="offer_info_title text-white">
                          Opening hours. . . . <i className="fal fa-clock"></i>
                        </h3>
                        <ul className="ul_li_block">
                          <li>
                            Monday
                            <span>9:00 - 18:00</span>
                          </li>
                          <li>
                            tuesday
                            <span>10:00 - 18:00</span>
                          </li>
                          <li>
                            wednestday
                            <span>11:00 - 18:00</span>
                          </li>
                          <li>
                            Thusday
                            <span>12:00 - 18:00</span>
                          </li>
                          <li>
                            Friday
                            <span>14:00 - 18:00</span>
                          </li>
                          <li>
                            saterday
                            <span>16:00 - 18:00</span>
                          </li>
                          <li>
                            Sunday
                            <span>closed</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="offerinfo_col col-lg-4">
                      <div
                        className="offer_info_item friday_offer text-center wow fadeInUp"
                        data-wow-delay=".3s"
                      >
                        <h3 className="offer_info_title text-uppercase">
                          Friday Offer. . . . <i className="fal fa-gift"></i>
                        </h3>
                        <img
                          src="assets/images/offer/img_03.png"
                          alt="image_not_found"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <section className="shop_section sec_ptb_120 bg_gray">
            <div className="container">
              <div className="section_title text-uppercase">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-8">
                    <h2
                      className="small_title wow fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <i className="fas fa-coffee"></i> special food items
                    </h2>
                    <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
                      Our popular food
                    </h3>
                  </div>

                  <div className="col-lg-6 col-md-4">
                    <div
                      className="abtn_wrap text-lg-end text-md-end wow fadeInUp"
                      data-wow-delay=".3s"
                    >
                      <a
                        className="btn btn_border border_black"
                        href="shop.html"
                      >
                        See full menu
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="shop_card wow fadeInUp" data-wow-delay=".1s">
                    <a className="wishlist_btn" href="#!">
                      <i className="fal fa-heart"></i>
                    </a>
                    <div className="share_btns">
                      <button type="button" className="share_btn">
                        <i className="fal fa-share-alt"></i>
                      </button>
                      <ul className="ul_li">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-youtube"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <a className="item_image" href="#">
                      <img
                        src="<?php echo $link;?>/images/product/<?php echo $productDetailsRes['images'];?>"
                        alt="<?php echo strtoupper($productDetailsRes['name']);?>"
                      />
                    </a>
                    <div className="item_content">
                      <h3 className="item_title text-uppercase">
                        <a href="#">Title</a>
                      </h3>
                      <div className="btns_group">
                        <span className="item_price bg_default_brown">
                          <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                          Details
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <section
            className="blog_section sec_ptb_120"
            style={{
              backgroundImage: "url(assets/images/backgrounds/bg_03.png)",
            }}
          >
            <div className="container">
              <div className="section_title text-uppercase text-center">
                <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
                  <i className="fas fa-coffee"></i> News & Blog
                </h2>
                <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
                  Latest news & Blog
                </h3>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="blog_grid wow fadeInUp" data-wow-delay=".1s">
                    <a className="item_image" href="blog_details.html">
                      <img
                        src="assets/images/blog/img_01.png"
                        alt="image_not_found"
                      />
                    </a>
                    <div className="item_content">
                      <h3 className="item_title text-uppercase">
                        <a href="blog_details.html">Americano Spacial Coffee</a>
                      </h3>
                      <p>
                        The coffee is brewed by first roasting the green coffee
                        beans over hot coals in a brazier.Once the beans are
                        roasted each participant is given...
                      </p>
                      <a
                        className="btn_text text-uppercase"
                        href="blog_details.html"
                      >
                        <span>Read More</span>{" "}
                        <i className="far fa-angle-double-right"></i>
                      </a>
                      <ul className="post_meta ul_li">
                        <li>
                          <a href="#!">
                            <i className="fal fa-user"></i> Rasalina De
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i className="fal fa-heart"></i> 36 like
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i className="fal fa-comment-alt"></i> 8 comments
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="blog_grid wow fadeInUp" data-wow-delay=".2s">
                    <a className="item_image" href="blog_details.html">
                      <img
                        src="assets/images/blog/img_02.png"
                        alt="image_not_found"
                      />
                    </a>
                    <div className="item_content">
                      <h3 className="item_title text-uppercase">
                        <a href="blog_details.html">Americano Spacial Coffee</a>
                      </h3>
                      <p>
                        The coffee is brewed by first roasting the green coffee
                        beans over hot coals in a brazier.Once the beans are
                        roasted each participant is given...
                      </p>
                      <a
                        className="btn_text text-uppercase"
                        href="blog_details.html"
                      >
                        <span>Read More</span>{" "}
                        <i className="far fa-angle-double-right"></i>
                      </a>
                      <ul className="post_meta ul_li">
                        <li>
                          <a href="#!">
                            <i className="fal fa-user"></i> Rasalina De
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i className="fal fa-heart"></i> 36 like
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i className="fal fa-comment-alt"></i> 8 comments
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="blog_grid wow fadeInUp" data-wow-delay=".3s">
                    <a className="item_image" href="blog_details.html">
                      <img
                        src="assets/images/blog/img_03.png"
                        alt="image_not_found"
                      />
                    </a>
                    <div className="item_content">
                      <h3 className="item_title text-uppercase">
                        <a href="blog_details.html">Americano Spacial Coffee</a>
                      </h3>
                      <p>
                        The coffee is brewed by first roasting the green coffee
                        beans over hot coals in a brazier.Once the beans are
                        roasted each participant is given...
                      </p>
                      <a
                        className="btn_text text-uppercase"
                        href="blog_details.html"
                      >
                        <span>Read More</span>{" "}
                        <i className="far fa-angle-double-right"></i>
                      </a>
                      <ul className="post_meta ul_li">
                        <li>
                          <a href="#!">
                            <i className="fal fa-user"></i> Rasalina De
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i className="fal fa-heart"></i> 36 like
                          </a>
                        </li>
                        <li>
                          <a href="#!">
                            <i className="fal fa-comment-alt"></i> 8 comments
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="load_more text-center wow fadeInUp"
                data-wow-delay=".1s"
              >
                <a
                  className="btn btn_border border_black text-uppercase"
                  href="blog.html"
                >
                  See all Blog
                </a>
              </div>
            </div>
          </section> */}
        </main>
      </div>
    </>
  );
};
