import React from "react";

export const Contactus = () => {
  return (
    <>
      <div class="body_wrap">
        <main>
          <section
            class="breadcrumb_section text-uppercase"
            style={{backgroundImage:"url(assets/images/breadcrumb/breadcrumb_bg_01.jpg)"}}
          >
            <div class="container">
              <h1
                class="page_title text-white wow fadeInUp"
                data-wow-delay=".1s"
              >
                Contact Us
              </h1>
              <ul
                class="breadcrumb_nav ul_li wow fadeInUp"
                data-wow-delay=".2s"
              >
                <li>
                  <a href="index_1.html">
                    <i class="fas fa-home"></i> Home
                  </a>
                </li>
                <li>Contact Us</li>
              </ul>
            </div>
            <div class="breadcrumb_icon_wrap">
              <div class="container">
                <div class="breadcrumb_icon wow fadeInUp" data-wow-delay=".3s">
                  <img
                    src="assets/images/feature/icon_01.png"
                    alt="icon_not_found"
                  />
                  <span class="bg_shape"></span>
                </div>
              </div>
            </div>
          </section>

          <section class="contact_section sec_ptb_120 bg_default_gray">
            <div class="container">
              <div
                class="contact_form bg_white wow fadeInUp"
                data-wow-delay=".1s"
              >
                <div class="main_contact_info_wrap">
                  <div class="contact_info_item">
                    <div class="item_icon">
                      <i class="fal fa-envelope"></i>
                    </div>
                    <div class="item_content">
                      <h3 class="item_title text-uppercase">Email Address</h3>
                      <p>cafehousesukna@gmail.com</p>
                    </div>
                  </div>
                  <div class="contact_info_item">
                    <div class="item_icon">
                      <i class="fal fa-map-marker-alt"></i>
                    </div>
                    <div class="item_content">
                      <h3 class="item_title text-uppercase">Our Location</h3>
                      <p>
                        Sukna, Near Sit College, Siliguri-734003, Darjeeling
                      </p>
                    </div>
                  </div>
                  <div class="contact_info_item">
                    <div class="item_icon">
                      <i class="fal fa-phone"></i>
                    </div>
                    <div class="item_content">
                      <h3 class="item_title text-uppercase">Phone Number</h3>
                      <p>+91-9476292238</p>
                    </div>
                  </div>
                </div>
                <form action="#">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form_item">
                        <input
                          type="text"
                          name="name"
                          placeholder="Your name:"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form_item">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your Mail:"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form_item">
                    <input
                      type="text"
                      name="subject"
                      placeholder="Enter Your Subject:"
                    />
                  </div>
                  <div class="form_item">
                    <textarea
                      name="message"
                      placeholder="Your Massage:"
                    ></textarea>
                  </div>
                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn btn_primary text-uppercase"
                    >
                      Send massage
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>

          {/* <div class="map_section">
            <div
              id="mapBox"
              class="wow fadeInUp"
              data-wow-delay=".1s"
              data-lat="40.701083"
              data-lon="-74.1522848"
              data-zoom="12"
              data-info="PO Box CT16122 Collins Street West, Victoria 8007, Australia."
              data-mlat="40.701083"
              data-mlon="-74.1522848"
            ></div>
          </div> */}
        </main>
      </div>
    </>
  );
};
