import { React, useEffect, useContext, useState } from "react";
import axios from "axios";
import webContent from "../include/Config";
import { useNavigate, useParams } from "react-router-dom";
import { MenuList } from "../include/MenuList";
import { SiteContext } from "../context/SiteContext";

export const Order = () => {
  const { siteData } = useContext(SiteContext);
  const { id } = useParams();
  const [loader, seLoader] = useState(true);
  const navigate = useNavigate();
  localStorage.setItem("tableNo", id);
  const checkTableStatus = () => {
    //alert(id);
    try {
      axios
        .post(
          webContent.webLink + "/api/tableCheck.php",
          { SUBMIT: "CHECKTABLE", TABLE_NO: localStorage.getItem("tableNo") },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          //alert(res.data.tablestatus);
          //console.log("Null " + res.data.tablestatus);
          //setTableStatus(res.data.tablestatus);
          //alert(tableSts);
          seLoader(false);
          if (res.data.tablestatus == "Close") {
            navigate("/reserved");
          }
          //return res.data.tablestatus;
        })
        .catch((e) => {
          console.log(`register error ${e}`);
          //setIsLoading(false);
        });
    } catch (err) {
      return { success: 0, message: "Server Error!" };
    }
  };
  useEffect(() => {
    checkTableStatus();
    if (id && id != 0) {
      var timestamp = new Date().getTime();
      //alert(timestamp.toString().substring(0, 10));
      if (localStorage.getItem("orderId") == null) {
        localStorage.setItem(
          "orderId",
          10 + timestamp.toString().substring(6, 10)
        );
      }
    } else {
      navigate("/");
    }
  }, []);
  return (
    <>
      {loader ? (
        <div>Loading....</div>
      ) : (
        <div>
          {siteData.ip == "notmatch" ? navigate("/menu") : (
            <MenuList />
          )}
        </div>
      )}
    </>
  );
};
