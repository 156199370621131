import React from "react";

export const PageError = () => {
  return (
    <>
      <div
        class="element-item chocolate greantea "
        data-category="chocolate"
        style={{ marginTop: "130px" }}
      >
        <div className="section_title text-uppercase text-center">
          <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
            <i className="fa fa-warning"></i> 404 ERROR
          </h2>
          <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
            PAGE NOT FOUND
          </h3>
        </div>
      </div>
    </>
  );
};
