import { React, useContext, useEffect, useState } from "react";
import { CartContext } from "../context/CartContext";
import { CartItems } from "./CartItems";
import { Link, NavLink } from "react-router-dom";
export const Header = () => {
  const { totalitem, cartPanel, cartPanelOpen } = useContext(CartContext);
  const [mobileMenu, setmobileMenu] = useState(0);

  const showMobileMenu = () => {
    if (mobileMenu == 0) {
      document.getElementById("main_menu_dropdown").style.display = "block";
      setmobileMenu(1);
    } else {
      document.getElementById("main_menu_dropdown").style.display = "none";
      setmobileMenu(0);
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      //alert("okkkkkkk")
      const scrolled = document.documentElement.scrollTop;
      //alert(scrolled);
      if (scrolled == 0) {
        document
          .getElementById("header_section")
          .classList.remove("stickyheader");
        //document.getElementById("nav-header").classList.add("py-5");
      } else {
        document.getElementById("header_section").classList.add("stickyheader");
        //document.getElementById("nav-header").classList.remove("py-5");
      }

      // what else do I need here
    };
  });

  return (
    <>
      {cartPanel ? (
        <div>
          {/* <button
            type="button"
            class="cartBtn"
            onClick={() => {
              cartPanelOpen();
            }}
          >
            <i class="fal fa-times"></i>
          </button> */}
          <CartItems />
        </div>
      ) : (
        <div></div>
      )}

      <div className="backtotop">
        <Link to="#" className="scroll">
          <i className="far fa-arrow-up"></i>
          <i className="far fa-arrow-up"></i>
        </Link>
      </div>

      {/* <div id="preloader"></div> */}

      <header className="header_section" id="header_section">
        <div className="content_wrap">
          <div className="container maxw_1560">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-6 col-6">
                <div className="brand_logo">
                  <Link className="brand_link" to="/">
                    <img
                      src="/assets/images/logo/logo_white_1x.png"
                      srcset="/assets/images/logo/logo_white_1x.png"
                      alt="CafeHouseSukna"
                    />
                  </Link>
                </div>
              </div>

              <div className="col-lg-10 col-md-6 col-6">
                <nav className="main_menu navbar navbar-expand-lg">
                  <button
                    className="mobile_menu_btn navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#main_menu_dropdown"
                    aria-controls="main_menu_dropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => {
                      setmobileMenu(1);
                      showMobileMenu();
                    }}
                  >
                    <span className="navbar-toggler-icon">
                      <i className="fal fa-bars"></i>
                    </span>
                  </button>
                  <div
                    className="main_menu_inner collapse navbar-collapse"
                    id="main_menu_dropdown"
                  >
                    <ul className="main_menu_list ul_li">
                      <li className="dropdown">
                        <NavLink
                          className="nav-link"
                          to="/"
                          id="home_submenu"
                          role="button"
                          style={({ isActive }) => ({
                            color: isActive ? "#c7a17a" : "#999999",
                          })}
                        >
                          Home
                        </NavLink>
                      </li>
                      <li className="dropdown">
                        <NavLink
                          className="nav-link"
                          to="/aboutus"
                          id="about_submenu"
                          role="button"
                          style={({ isActive }) => ({
                            color: isActive ? "#c7a17a" : "#999999",
                          })}
                        >
                          About Us
                        </NavLink>
                      </li>
                      <li className="dropdown">
                        <NavLink
                          className="nav-link"
                          to="/menu"
                          id="about_submenu"
                          role="button"
                          style={({ isActive }) => ({
                            color: isActive ? "#c7a17a" : "#999999",
                          })}
                        >
                          Menu
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          className="nav-link"
                          to="/contactus"
                          style={({ isActive }) => ({
                            color: isActive ? "#c7a17a" : "#999999",
                          })}
                        >
                          Contact us
                        </NavLink>
                      </li>
                    </ul>
                  </div>

                  <ul className="header_btns_group ul_li_right">
                    {localStorage.getItem("orderId") != "" ? (
                      <li>
                        <button
                          type="button"
                          className="bt_open"
                          onClick={() => {
                            cartPanelOpen();
                          }}
                        >
                          <i className="fal fa-shopping-bag"></i>
                          <small className="cart_counter">{totalitem}</small>
                        </button>
                      </li>
                    ) : (
                      <li></li>
                    )}
                    <li>
                      <Link
                        className="btn btn_primary text-uppercase"
                        to="/contactus"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div
          className="main_search_collapse collapse"
          id="main_search_collapse"
        >
          <div className="main_search_form card">
            <div className="container maxw_1560">
              <form action="#">
                <div className="form_item">
                  <input
                    type="search"
                    name="search"
                    placeholder="Search here..."
                  />
                  <button type="submit" className="submit_btn">
                    <i className="fal fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
