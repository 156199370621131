import React from 'react'

export const SmallAbout = () => {
    return (
        <>
            <section class="feature_primary_section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <div class="feature_primary wow fadeInUp" data-wow-delay=".1s">
                                <div class="item_icon">
                                    <span class="item_serial">1</span>
                                    <img src="assets/images/feature/icon_01.png" alt="icon_not_found" />
                                </div>
                                <h3 class="item_title text-uppercase">awesomae aroma</h3>
                                <p class="mb-0">
                                    Having the best coffee drinking experience requires knowing what makes it taste good
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <div class="feature_primary wow fadeInUp" data-wow-delay=".2s">
                                <div class="item_icon">
                                    <span class="item_serial">2</span>
                                    <img src="assets/images/feature/icon_02.png" alt="icon_not_found" />
                                </div>
                                <h3 class="item_title text-uppercase">high quality</h3>
                                <p class="mb-0">
                                    Exceptional and hogh quality coffee can only come from exceptional beans
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <div class="feature_primary wow fadeInUp" data-wow-delay=".3s">
                                <div class="item_icon">
                                    <span class="item_serial">3</span>
                                    <img src="assets/images/feature/icon_03.png" alt="icon_not_found" />
                                </div>
                                <h3 class="item_title text-uppercase">pure grades</h3>
                                <p class="mb-0">
                                    The coffee quality is associated with pre-harvest and post-harvest management sorting, grading.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-4 col-sm-6">
                            <div class="feature_primary wow fadeInUp" data-wow-delay=".4s">
                                <div class="item_icon">
                                    <span class="item_serial">4</span>
                                    <img src="assets/images/feature/icon_04.png" alt="icon_not_found" />
                                </div>
                                <h3 class="item_title text-uppercase">proper roasting</h3>
                                <p class="mb-0">
                                    The degree of roasting can have a great influence on the taste of the coffee, so it is important to choose the right roasting
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="about_section sec_ptb_80">
                <div class="container">
                    <div class="row align-items-center justify-content-lg-between">
                        <div class="col-lg-6 col-md-6 order-last">
                            <div class="about_image1 wow fadeInRight" data-wow-delay=".1s">
                                <img src="assets/images/about/img_01.png" alt="image_not_found" />

                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="about_content">
                                <div class="section_title text-uppercase">
                                    <h2 class="small_title"><i class="fas fa-coffee"></i> about us</h2>
                                    <h3 class="big_title wow fadeInUp" data-wow-delay=".1s">
                                        There is all about cafe house Sukna
                                    </h3>
                                </div>
                                <p class="wow fadeInUp" data-wow-delay=".2s">
                                    Cafe House Siliguri is the latest wing added to Techno India Group's crown, targeting the Youths & Foreigners, is nestled amongst the misty greens of the scenic beauty in Sukna. The coffee lovers can experience the picturesque Himalayan peaks, gushing streams and swaying orchids whilst they sip on the choicest selection of coffee beads along with variety of teas. It will offer you an experience of a lifetime with home brewed coffee, aromatic tea and mouth-watering snacks.
                                </p>
                                <ul class="about_info ul_li_block">
                                    <li class="wow fadeInUp" data-wow-delay=".3s">
                                        <h4 class="text-uppercase"><i class="far fa-square-full"></i> There is all about our Cafe House Sukna</h4>
                                        <p class="mb-0">
                                            The coffee is brewed by first roasting the green coffee beans over hot coals in a brazier. Once
                                            the beans are roasted each participant is given an opportunity to sample
                                        </p>
                                    </li>
                                    <li class="wow fadeInUp" data-wow-delay=".4s">
                                        <h4 class="text-uppercase"><i class="far fa-square-full"></i> This is followed by the grinding</h4>
                                        <p class="mb-0">
                                            The coffee is brewed by first roasting the green coffee beans over hot coals in a brazier. Once
                                            the beans are roasted each participant is given an opportunity to sample the aromatic smoke by
                                            wafting it towards them.
                                        </p>
                                    </li>
                                </ul>
                                <ul class="btns_group ul_li wow fadeInUp" data-wow-delay=".5s">
                                    <li>
                                        <a class="btn btn_primary text-uppercase" href="about.html">Learn more</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
