import axios from "axios";
import { createContext, useState, useEffect } from "react";
import webContent from "../include/Config";
export const SiteContext = createContext();

export const SiteContextProvider = ({ children }) => {
  const [siteData, setSiteData] = useState({});

  const getSiteDetails = () => {
    try {
      axios
        .post(
          webContent.webLink + "/api/siteDetails.php",
          { SUBMIT: "SITEDETAILS"},
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          //console.log(res.data);
          setSiteData(res.data);
        })
        .catch((e) => {
          console.log(`register error ${e}`);
          //setIsLoading(false);
        });
    } catch (err) {
      return { success: 0, message: "Server Error!" };
    }
  };

  useEffect(()=>{
    getSiteDetails()
  },[])

  return <SiteContext.Provider value={{siteData}}>{children}</SiteContext.Provider>;
};
