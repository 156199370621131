import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Home } from "./components/Home";
import { Header } from "./include/Header";
import { Footer } from "./include/Footer";
import { CartContextProvider } from "./context/CartContext";
import { SiteContextProvider } from "./context/SiteContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Menu } from "./components/Menu";
import { Aboutus } from "./components/Aboutus";
import { Contactus } from "./components/Contactus";
import { Order } from "./components/Order";
import OrberSuccess from "./components/OrberSuccess";
import { Reserved } from "./components/Reserved";
import { PageError } from "./components/PageError";
function App() {
  return (
    <>
      <SiteContextProvider>
        <CartContextProvider>
          <Router>
            <Header />
            <Routes>
              <Route exect path="/" element={<Home />} />
              <Route path="/menu" element={<Menu />} />
              <Route path="/aboutus" element={<Aboutus />} />
              <Route path="/contactus" element={<Contactus />} />
              <Route path="/order/:id" element={<Order />} />
              <Route path="/success/:id" element={<OrberSuccess />} />
              <Route path="/reserved" element={<Reserved />} />
              <Route path="*" element={<PageError />} />
            </Routes>
            <ToastContainer />
            <Footer />
          </Router>
        </CartContextProvider>
      </SiteContextProvider>
    </>
  );
}

export default App;
