import { React, useEffect, useState, useContext } from "react";
import axios from "axios";
import webContent from "./Config";
import { CartContext } from "../context/CartContext";
export const MenuItems = (props) => {
  //alert(props.state.type)
  const { cartitems, addtoCart } = useContext(CartContext);
  const [quantity, setQuantity] = useState(1);
  const [items, setItems] = useState([]);
  console.log(localStorage.getItem("tableNo"));
  //const [cartitems, setCartItems] = useState([]);
  const productList = async () => {
    axios
      .post(
        webContent.webLink + "/api/itemList.php",
        { SUBMIT: "ITEMS", TYPE_ITEM: props.state.type },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        setItems(res.data);
      })
      .catch((e) => {
        console.log(`register error ${e}`);
        //setIsLoading(false);
      });
  };
  const cartHandler = (product, qty) => {
    addtoCart({ product, qty });
  };

  useEffect(() => {
    productList();
  }, [cartitems]);
  return (
    <>
      {items.length > 0 ? (
        <div class="element-item chocolate greantea " data-category="chocolate">
          {items.length > 0 &&
            items.map((proObj, index) => (
              <div class="recipe_item" style={{ margin: "20px 0px" }}>
                <div class="content_col" style={{display:"table"}}>
                  <div class="p_images">
                    <img
                      src={
                        webContent.webLink +
                        "/images/product/" +
                        proObj.productDetails.images
                      }
                    />
                  </div>
                  <div class="item_content cart_cont">
                    <h3 class="item_title text-uppercase">
                      <a href="#">{proObj.productDetails.name}</a>
                    </h3>
                    <p class="mb-0">{proObj.productDetails.details}</p>
                    {localStorage.getItem("tableNo") != null ? (
                      <div>
                        <select
                          class="btn btn-primary"
                          onChange={(e) => setQuantity(e.target.value)}
                          style={{
                            background: "#f0ddc3",
                            borderRadius: "5px",
                            color: "black",
                            padding: "5px",
                          }}
                          id={"qty" + parseInt(index + 1)}
                        >
                          {[...Array(20)]
                            .map((_, i) => i + 1)
                            .map((i) => (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            ))}
                        </select>
                        &nbsp;&nbsp;
                        <button
                          class="btn btn_primary text-uppercase"
                          style={{ padding: "5px" }}
                          type="submit"
                          onClick={() =>
                            cartHandler(proObj.productDetails, quantity)
                          }
                        >
                          Add To Cart
                        </button>
                      </div>
                    ) : (
                      <div>&nbsp;</div>
                    )}
                  </div>
                </div>
                <div class="content_col">
                  <strong class="item_price">
                    <sub>
                      <i class="fa fa-inr" aria-hidden="true"></i>
                    </sub>
                    {proObj.productDetails.price}
                  </strong>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <div class="element-item chocolate greantea " data-category="chocolate">
          <div className="section_title text-uppercase text-center">
            <h2 className="small_title wow fadeInUp" data-wow-delay=".1s">
              <i className="fas fa-coffee"></i> [{items.length} items found]
            </h2>
            <h3 className="big_title wow fadeInUp" data-wow-delay=".2s">
              NO RECORDS FOUND
            </h3>
          </div>
        </div>
      )}
    </>
  );
};
